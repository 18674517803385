@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slowFloat {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(-2%, 2%) rotate(3deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

